<template>
  <div id="app">
    <nav>
      <router-link to="/"></router-link>
    </nav>
   
    <keep-alive> <router-view></router-view> </keep-alive>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  watch: {},
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
  outline: none;
}
a {
  text-decoration: none;
}
body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body {
  font-family: "Noto Sans SC", sans-serif;
}
/* 垂直滚动条样式 */
/* 宽度 */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}
/* 背景色 */
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
/* 滑块颜色 */
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
/* 水平滚动条样式 */
/* 高度 */
::-webkit-scrollbar {
  height: 5px;
  border-radius: 5px;
}
/* 背景色 */
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
/* 滑块颜色 */
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #333 !important;
  background-color: transparent;
  transition: background-color 50000s ease-in-out 0s;
}
input {
  background-color: transparent;
}
</style>
