import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import { Base64 } from "js-base64";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "登录",
      requiresAuth: false,
    },
    component: function () {
      return import("@/views/HomeView/HomeView.vue");
    },
  },
  {
    path: "/screen",
    name: "screen",
    meta: {
      title: "大屏",
      requiresAuth: false,
    },
    component: function () {
      return import("@/views/PanelView/screen/screen.vue");
    },
  },
  {
    path: "/setUp",
    name: "setUp",
    meta: {
      title: "设置",
      requiresAuth: false,
    },
    component: function () {
      return import("@/views/PanelView/setUp/setUp.vue");
    },
  },
  {
    path: "/outland",
    name: "outland",
    meta: {
      title: "outland",
      requiresAuth: false,
    },
    component: function () {
      return import("@/views/PanelView/setUp/outland/outland.vue");
    },
  },
  {
    path: "/login",
    name: "home",
    meta: {
      title: "跳转",
      requiresAuth: false,
    },
    component: function () {
      return import("@/views/transition.vue");
    },
  },
  {
    path: "/panel",
    name: "panel",
    redirect: "/panel/Panel/LeftPanel",
    meta: {
      title: "面板",
      requiresAuth: true,
    },
    component: function () {
      return import("@/views/PanelView/index.vue");
    },
    children: [
      {
        path: "Panel/LeftPanel",
        name: "LeftPanel",
        meta: {
          title: "左侧面板",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/LeftPanel/LeftPanel.vue");
        },
      },
      {
        path: "/videoUrl",
        name: "videoUrl",
        meta: {
          title: "videoUrl",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/videoUrl/videoUrl.vue");
        },
      },

      {
        path: "/play",
        name: "test",
        meta: {
          title: "test",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/test/index.vue");
        },
      },

      {
        path: "/playback",
        name: "recording",
        meta: {
          title: "recording",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/Recording/Recording.vue");
        },
      },
      {
        path: "/equipment",
        name: "equipment",
        meta: {
          title: "equipment",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/equipment/tab.vue");
        },
      },
      {
        path: "/devices",
        name: "devices",
        meta: {
          title: "devices",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/equipment/Devices/equipment.vue");
        },
      },
      {
        path: "/playcenter",
        name: "playcenter",
        meta: {
          title: "playcenter",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/Center/Center.vue");
        },
      },
      {
        path: "/nat",
        name: "nat",
        meta: {
          title: "nat",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/equipment/Nat/Nat.vue");
        },
      },
      {
        path: "/node",
        name: "node",
        meta: {
          title: "node",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/Node/node.vue");
        },
      },
      {
        path: "/serve",
        name: "serve",
        meta: {
          title: "serve",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/Serve/serveTo.vue");
        },
      },

      {
        path: "/baidu",
        name: "baidu",
        meta: {
          title: "serve",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/Baidu/Baidu.vue");
        },
      },
      {
        path: "/ai_alarm",
        name: "ai_alarm",
        meta: {
          title: "ai_alarm",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/Police/police.vue");
        },
      },
      {
        path: "/status",
        name: "status",
        meta: {
          title: "status",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/Status/status.vue");
        },
      },

      {
        path: "/confiFile",
        name: "confiFile",
        meta: {
          title: "confiFile",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/confiFile/confiFile.vue");
        },
      },

      {
        path: "/setting",
        name: "setting",
        meta: {
          title: "setting",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/Setting/setting.vue");
        },
        children: [
          {
            path: "/port",
            name: "port",
            meta: {
              title: "port",
              requiresAuth: true,
              keepAlive: true,
            },
            component: function () {
              return import("@/views/PanelView/Setting/port/port.vue");
            },
          },
          {
            path: "/Streaming",
            name: "Streaming",
            meta: {
              title: "Streaming",
              requiresAuth: true,
              keepAlive: true,
            },
            component: function () {
              return import(
                "@/views/PanelView/Setting/Streaming/Streaming.vue"
              );
            },
          },
          {
            path: "/videoserve",
            name: "videoserve",
            meta: {
              title: "videoserve",
              requiresAuth: true,
              keepAlive: true,
            },
            component: function () {
              return import(
                "@/views/PanelView/Setting/VideoService/VideoService.vue"
              );
            },
          },
          {
            path: "/disk",
            name: "disk",
            meta: {
              title: "disk",
              requiresAuth: true,
              keepAlive: true,
            },
            component: function () {
              return import("@/views/PanelView/Setting/Disk/disk.vue");
            },
          },
          {
            path: "/watermark",
            name: "watermark",
            meta: {
              title: "watermark",
              requiresAuth: true,
              keepAlive: true,
            },
            component: function () {
              return import(
                "@/views/PanelView/Setting/watermarkSetting/watermarkSetting.vue"
              );
            },
          },
          {
            path: "/module",
            name: "module",
            meta: {
              title: "module",
              requiresAuth: true,
              keepAlive: true,
            },
            component: function () {
              return import(
                "@/views/PanelView/Setting/moduleDisplay/moduleDisplay.vue"
              );
            },
          },
        ],
      },
      {
        path: "/plan",
        name: "plan",
        meta: {
          title: "plan",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/VideoPlan/videoplan.vue");
        },
      },
      {
        path: "/user",
        name: "user",
        meta: {
          title: "user",
          requiresAuth: true,
          keepAlive: true,
        },
        children: [
          {
            path: "/role",
            name: "role",
            meta: {
              title: "role",
              requiresAuth: true,
              keepAlive: true,
            },
            component: function () {
              return import("@/views/PanelView/User/role/role.vue");
            },
          },
          {
            path: "/administer",
            name: "administer",
            meta: {
              title: "administer",
              requiresAuth: true,
              keepAlive: true,
            },
            component: function () {
              return import("@/views/PanelView/User/admin/admin.vue");
            },
          },
        ],
        component: function () {
          return import("@/views/PanelView/User/user.vue");
        },
      },
      {
        path: "/tv",
        name: "tv",
        meta: {
          title: "tv",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/Tvwall/tv.vue");
        },
      },
      {
        path: "/control",
        name: "control",
        meta: {
          title: "control",
          requiresAuth: true,
          keepAlive: true,
        },
        component: function () {
          return import("@/views/PanelView/Tvcontrol/tv.vue");
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let token = window.sessionStorage.getItem("token");
  let hostname = window.sessionStorage.getItem("hostname");
  let HTTP = window.sessionStorage.getItem("HTTP");
  let port = window.sessionStorage.getItem("port");
  let username = window.sessionStorage.getItem("username");
  let passwords = window.sessionStorage.getItem("password");
  let num = 1;
  if (token) {
    let url = `${HTTP}${hostname}:${port}/ReadUserList`;
    let data = {
      token: token,
    };
    axios({
      method: "get",
      url,
      params: data, // 使用 params 而不是 data
    })
      .then((response) => {
        if (response.data.result == 0) {
          next();
        } else {
          if (num < 3) {
            let data = {
              username: username,
              password: Base64.encode(passwords),
            };
            let url = `${HTTP}${hostname}:${port}/LoginToService`;
            axios({
              method: "get",
              url,
              params: data, // 使用 params 而不是 data
            })
              .then((response) => {
                window.sessionStorage.setItem("token", response.data.token);
                next();
              })
              .catch((error) => {
                num + 1;
              });
          }else{
            
          }
        }
      })
      .catch((error) => {});
    //
  } else {
    next();
    // if (to.path === '/') {
    //   next();
    // } else {
    //   next('/');
    // }
  }
});

export default router;
