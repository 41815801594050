export default {
  // 返回年月日
  getTime() {
    var date = new Date();
    //年 getFullYear()：四位数字返回年份
    var year = date.getFullYear(); //getFullYear()代替getYear()
    //月 getMonth()：0 ~ 11
    var month = date.getMonth() + 1;
    //日 getDate()：(1 ~ 31)
    var day = date.getDate();
    // var time =`${year}${month < 10 ? month : '0'+ month}${day < 10 ? "0" + day : day}`
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    let time = `${year}${month}${day}`;
    return time;
  },
  getTimeto() {
    var date = new Date();
    //年 getFullYear()：四位数字返回年份
    var year = date.getFullYear(); //getFullYear()代替getYear()
    //月 getMonth()：0 ~ 11
    var month = date.getMonth() + 1;
    //日 getDate()：(1 ~ 31)
    var day = date.getDate();
    // var time =`${year}${month < 10 ? month : '0'+ month}${day < 10 ? "0" + day : day}`
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    let time = year + "-" + month + "-" + day;
    return time;
  },
  // 中国标准时间转为 yyyy-mm-dd hh-mm-ss
  getDate(date) {
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let M = date.getMinutes();
    M = M < 10 ? "0" + M : M;
    let s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    let dateTime = y + "-" + m + "-" + d + " " + h + ":" + M + ":" + s;
    return dateTime;
  },
  getDateTime() {
    // 创建一个新的 Date 对象
    let currentDate = new Date();
    // 获取当前时间的小时、分钟和秒
    let hours = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    let seconds = currentDate.getSeconds();
    // 格式化时间，确保小时、分钟和秒始终有两位数
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    // 构建表示当前时间的字符串
    let currentTime = hours + ":" + minutes + ":" + seconds;
    return currentTime;
  },
  getDateTimes() {
    let date = new Date();
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let M = date.getMinutes();
    M = M < 10 ? "0" + M : M;
    let s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    let dateTime = y + "-" + m + "-" + d + "_" + h + ":" + M + ":" + s;
    return dateTime;
  },
  aiTime() {
    var date = new Date();
    //年 getFullYear()：四位数字返回年份
    var year = date.getFullYear(); //getFullYear()代替getYear()
    //月 getMonth()：0 ~ 11
    var month = date.getMonth() + 1;
    //日 getDate()：(1 ~ 31)
    var day = date.getDate();
    var day1 = date.getDate();
    // var time =`${year}${month < 10 ? month : '0'+ month}${day < 10 ? "0" + day : day}`
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    if (day1 < 10) {
      day1 = "0" + day1;
    }
    let time = `${year}-${month}-${day} 00:00:00`;
    let time1 = `${year}-${month}-${day1} 23:59:59`;

    let stre = `${year}${month}${day}_000000`;
    let end = `${year}${month}${day1}_235959`;
    let arr = {
      itme: [time, time1],
      choice: [stre, end],
    };
    return arr;
  },
  aiTimeC() {
    var date = new Date();
    //年 getFullYear()：四位数字返回年份
    var year = date.getFullYear(); //getFullYear()代替getYear()
    //月 getMonth()：0 ~ 11
    var month = date.getMonth() + 1;
    //日 getDate()：(1 ~ 31)
    var day = date.getDate();
    var day1 = date.getDate();
    // var time =`${year}${month < 10 ? month : '0'+ month}${day < 10 ? "0" + day : day}`
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    if (day1 < 10) {
      day1 = "0" + day1;
    }
    let time = `${year}-${month}-${day} 00:00:00`;
    let time1 = `${year}-${month}-${day1} 00:01:00`;

    let stre = `${year}${month}${day}_000000`;
    let end = `${year}${month}${day1}_000100`;
    let stampStre = new Date(time).getTime();
    let stampEnd = new Date(time1).getTime();
    let arr = {
      itme: [time, time1],
      choice: [stre, end],
      stamp: [stampStre, stampEnd],
    };
    return arr;
  },
  getTimes() {
    var myDate = new Date();
    var myYear = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
    var myMonth = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
    var myToday = myDate.getDate(); //获取当前日(1-31)
    var myDay = myDate.getDay(); //获取当前星期X(0-6,0代表星期天)
    var myHour = myDate.getHours(); //获取当前小时数(0-23)
    var myMinute = myDate.getMinutes(); //获取当前分钟数(0-59)
    var mySecond = myDate.getSeconds(); //获取当前秒数(0-59)
    var week = [
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六",
    ];

    myMonth = myMonth < 10 ? "0" + myMonth : myMonth;
    myToday = myToday < 10 ? "0" + myToday : myToday;
    myHour = myHour < 10 ? "0" + myHour : myHour;
    myMinute = myMinute < 10 ? "0" + myMinute : myMinute;
    mySecond = mySecond < 10 ? "0" + mySecond : mySecond;
    var nowTime;
    nowTime = [
      myHour + ":" + myMinute + ":" + mySecond,
      myYear +  "-"  + myMonth +  "-"  + myToday
      // + week[myDay],
    ];

    return nowTime;
  },
};
