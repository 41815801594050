const state = {
    checked: ""

};

const mutations = {
    change(state, val) {
        state.checked = val
    }
};

const actions = {
};

const getters = {
    checked: (state) => state.checked
};

export default {
    namespaced: true, // 启用命名空间
    state,
    mutations,
    actions,
    getters,
};

